import {Layout} from 'antd'
import {WithRouterProps} from 'next/dist/client/with-router'
import {NextRouter, withRouter} from 'next/router'
import React, {ReactElement, ReactNode} from 'react'
import NavBar from '@components/navbar'

import {NextPage} from 'next'
import './layout.module.css'
import Loader from '@components/misc/loader'
import Error from '@components/misc/error'

const {Sider, Content, Header} = Layout

interface Router extends NextRouter {
  path: string
  breadcrumbName: string
}

interface Props extends WithRouterProps {
  router: Router
  sider?: ReactNode
  loading?: boolean
  error?: number
}

const AppLayout = (props: React.PropsWithChildren<Props>) => {
  // check if the current page is the root page => dashboard page
  const isRoot = props.router.pathname === '/'

  return (
    <Layout style={{minHeight: '100vh'}}>
      <Header className='header'>
        <NavBar />
      </Header>
      {props.loading ? (
        <Loader />
      ) : (
        <Layout>
          {!!props.error && <Error statusCode={props.error} />}
          {!props.error && isRoot && (
            <Layout className='dashboard-layout-background'>{props.children}</Layout>
          )}
          {!props.error && !isRoot && (
            <>
              {props.sider && (
                <Sider theme='light' className='lookup-sider'>
                  {props.sider}
                </Sider>
              )}
              <Layout>
                <Content className='site-layout-background ps-1 pb-3'>{props.children}</Content>
              </Layout>
            </>
          )}
        </Layout>
      )}
    </Layout>
  )
}

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

export default withRouter(AppLayout)
