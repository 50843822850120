import {useApp} from '@store/app'
import {Button, Result} from 'antd'
import {useRouter} from 'next/router'
import {useMemo} from 'react'

interface Props {
  statusCode?: number
}

const Error = ({statusCode}: Props) => {
  const {t} = useApp()
  const router = useRouter()

  const status = useMemo(() => {
    switch (statusCode) {
      case 404:
        return '404'
      case 403:
        return '403'
      default:
        return '500'
    }
  }, [statusCode])

  return (
    <Result
      status={status}
      title={t(`error.${status}.title`)}
      subTitle={t(`error.${status}.subtitle`)}
      extra={
        <Button type='primary' onClick={() => router.push('/')}>
          {t('error.homeButton')}
        </Button>
      }
    />
  )
}

export default Error
