import {Row, Space, Tag, theme} from 'antd'
import env from '@beam-australia/react-env'
import {useMemo} from 'react'
import Link from 'next/link'

const {useToken} = theme

const InmemoriEnvironment = () => {
  const {token} = useToken()
  const currentBranch = env('BRANCH_NAME')

  const branch = useMemo(() => {
    return currentBranch?.replace('erp-', '') || 'Unknown'
  }, [currentBranch])

  const color = useMemo(() => {
    switch (branch) {
      case 'prod':
        return token.colorPrimary
      case 'play':
        return token.colorWarning
      default:
        return token.colorError
    }
  }, [branch, token.colorPrimary, token.colorWarning, token.colorError])

  return (
    <Row align='middle'>
      <Space>
        <Link href='/'>
          <img
            src='https://res.cloudinary.com/inmemori/image/upload/web-assets/brand/logo-inmemori-black.svg'
            width={150}
          />
        </Link>
        <Tag
          className='cursor-pointer'
          onClick={() => {
            if (branch === 'prod') window.location.replace('https://erp-play.inmemori.com')
            else if (branch === 'play') window.location.replace('https://erp.inmemori.com')
          }}
          color={color}>
          {branch}
        </Tag>
      </Space>
    </Row>
  )
}

export default InmemoriEnvironment
