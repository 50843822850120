import {useApp} from '@store/app'
import {useInmemoriServices} from '@services'
import {useQuery} from '@tanstack/react-query'
import {CeremonyDashboard} from '@components/dashboard/CeremonyDashboard'
import {useGetQuery, useLookupQuery} from '@queries/domains'
import {DataType, Filters, ProjectStatus, ProjectType} from '@components/dashboard/dashboard.t'
import {ConfigProvider, Skeleton, Typography} from 'antd'
import DashboardFilters from './filters'
import {useCallback, useMemo, useState} from 'react'
import {House} from '@shared/interfaces'

const {Title} = Typography

interface CeremonyDashboardConfigs {
  key: keyof DataType
  columnType: ProjectStatus
  title: string
  color: string
}

export const Dashboard = () => {
  const {t, isInitialized} = useApp()

  const erpApi = useInmemoriServices().erpApiService

  const [filters, setFilters] = useState<Filters>({})

  const housesData = useGetQuery<House>('houses')

  const dashboardData = useQuery({
    queryFn: () => erpApi.getDashboardData(),
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    enabled: isInitialized
  })

  //get categories of type "task" (because tasks column are dynamicaly created in the dashboard)
  const {
    data: taskCategories,
    error,
    isError
  } = useLookupQuery('categories', {limit: 30, q: {type: 'task'}}, {enabled: isInitialized})

  // taskCategories order is based on checklist sequence (_id: 6487446be0fd9ea248d7293f in /domain/sequences)
  const checklistSequenceId = '6487446be0fd9ea248d7293f'
  const {data: taskCategoriesOrder} = useLookupQuery('sequences', {
    limit: 15,
    q: {
      _id: checklistSequenceId
    }
  })

  const filterData = useCallback(
    (data: ProjectType[]) => {
      if (filters.houses?.length) {
        data = data.filter(({houses}) => houses.some(({_id}) => filters.houses?.includes(_id)))
      }

      return data
    },
    [filters]
  )

  const getDasboardData = useCallback(
    (key: keyof DataType) => {
      const currentData = dashboardData.data?.data?.data?.[key]

      return filterData(currentData || [])
    },
    [dashboardData, filterData]
  )

  const ceremonyDashboardConfigs: CeremonyDashboardConfigs[] = useMemo(
    () => [
      {
        key: 'futureProjects',
        columnType: ProjectStatus.FUTURE,
        title: 'Cérémonies à venir',
        color: '#935a55'
      },
      {
        key: 'passedProjects',
        columnType: ProjectStatus.PASSED,
        title: 'Cérémonies passées',
        color: '#6C6F4D'
      }
    ],
    []
  )

  if (isError || error) return <div className='dashboard-centered'>{t('dashboard.error')}</div>

  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: '#303133'
        }
      }}>
      <div className='dashboard-container'>
        <div className='dashboard-element'>
          <Title level={2} className='dashboard-title'>
            {t('dashboard.title')}
          </Title>

          <DashboardFilters houses={housesData} filters={filters} onFilterChange={setFilters} />
        </div>
        {(dashboardData.isLoading || housesData.isLoading) && (
          <div className='dashboard-element'>
            <Skeleton />
          </div>
        )}
        {!dashboardData.isLoading &&
          !housesData.isLoading &&
          ceremonyDashboardConfigs.map(({key, columnType, title, color}) => (
            <div className='dashboard-element' key={key}>
              <CeremonyDashboard
                data={getDasboardData(key)}
                taskCategories={taskCategories?.data || []}
                taskCategoriesOrder={taskCategoriesOrder?.data}
                columnType={columnType}
                title={title}
                headerColor='white'
                headerBgColor={color}
                isLoading={dashboardData.isLoading}
              />
            </div>
          ))}
      </div>
    </ConfigProvider>
  )
}
