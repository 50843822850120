import React from 'react'
import {Progress} from 'antd'
import {CheckCircleTwoTone} from '@ant-design/icons'

interface ProgressBarProps {
  percent: number
}

const colorRanges = [
  {max: 40, color: '#E48E4F', trailColor: '#F2E0D4'},
  {max: 60, color: '#EBC766', trailColor: '#F1EAD9'},
  {max: 80, color: '#9FAB56', trailColor: '#E7EAE3'},
  {max: 100, color: '#56AB7E', trailColor: '#D5E6DD'}
]

const getColors = (percent: number) => {
  const roundedPercent = Math.round(percent)
  const range = colorRanges.find((r) => roundedPercent <= r.max)
  return range
    ? {strokeColor: range.color, trailColor: range.trailColor}
    : {strokeColor: '', trailColor: ''}
}

export const ProgressBar: React.FC<ProgressBarProps> = ({percent}) => {
  if (percent === 100) {
    return <CheckCircleTwoTone twoToneColor='#52c41a' style={{fontSize: '20px'}} />
  }

  const {strokeColor, trailColor} = getColors(percent)

  return (
    <Progress
      showInfo={false}
      percent={percent}
      className='dashboard-progress'
      strokeColor={strokeColor}
      trailColor={trailColor}
    />
  )
}
