import React, {useState, useEffect, useMemo} from 'react'
import {useApp} from '@store/app'
import {ConfigProvider, Table, TablePaginationConfig, Typography} from 'antd'
import {CeremonyDashboardProps, ProjectType} from '@components/dashboard/dashboard.t'
import styled from '@emotion/styled'
import {getColumnConfiguration} from '@components/dashboard/columnsConfig'
import _ from 'lodash'

const {Title} = Typography

export const CeremonyDashboard: React.FC<CeremonyDashboardProps> = ({
  data,
  taskCategories,
  taskCategoriesOrder,
  columnType,
  title,
  headerColor,
  headerBgColor,
  isLoading
}) => {
  const {t} = useApp()

  const [scrollSize, setScrollSize] = useState({x: 1000})

  useEffect(() => {
    const calculateScrollSize = () => {
      const sizeX: number = window.innerWidth
      setScrollSize({x: sizeX - 100})
    }

    const debouncedCalculateScrollSize = _.debounce(calculateScrollSize, 300)

    calculateScrollSize()
    window.addEventListener('resize', debouncedCalculateScrollSize)

    return () => window.removeEventListener('resize', debouncedCalculateScrollSize)
  }, [])

  const finalColumns = useMemo(() => {
    return getColumnConfiguration(columnType, t, taskCategories, taskCategoriesOrder)
  }, [columnType, t, taskCategories, taskCategoriesOrder])

  const paginationConfig = {
    pageSize: 20,
    position: ['bottomLeft'],
    showSizeChanger: false
  }

  const StyledTable = styled(Table<ProjectType>)`
    .ant-table-header {
      background-color: ${headerBgColor};
    }

    .ant-table-header > table {
      width: auto !important;
      min-width: unset !important;
    }

    .ant-table-body > table {
      width: auto !important;
      min-width: unset !important;
    }

    .ant-table {
      display: inline-table !important;
      width: auto !important;
    }
  `

  return (
    <>
      <Title level={3} className='dashboard-title'>
        {title}
      </Title>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: headerBgColor,
              headerColor: headerColor
            }
          }
        }}>
        <StyledTable
          className='dashboard-table'
          columns={finalColumns}
          dataSource={data}
          pagination={paginationConfig as TablePaginationConfig}
          loading={isLoading}
          scroll={scrollSize}
          tableLayout='fixed'
          size='small'
          sticky
        />
      </ConfigProvider>
    </>
  )
}
