import type {ReactElement} from 'react'

import Layout, {NextPageWithLayout} from '@layouts/layout'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import {GetStaticProps} from 'next'
import HomePage from '@components/pages/home'

const Page: NextPageWithLayout = () => {
  return <HomePage />
}

Page.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>
}

export const getStaticProps: GetStaticProps = async () => {
  const props = await serverSideTranslations('fr', ['common'])
  return {props}
}

export default Page
