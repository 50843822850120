import React from 'react'
import {Avatar, Tooltip} from 'antd'
import {TeamMembers, PersonType} from '@components/dashboard/dashboard.t'

interface AvatarGroupProps {
  team: TeamMembers
}

const ROLE_ORDER = ['creator', 'attendant', 'officiant', 'facilitator']

export const AvatarGroup: React.FC<AvatarGroupProps> = ({team}) => {
  // clean the object to put the role as a key and sort by order
  const membersArray = Object.entries(team)
    .sort(([a], [b]) => ROLE_ORDER.indexOf(a) - ROLE_ORDER.indexOf(b))
    .map((v) => v.at(1))

  const getMemberInfo = (member: PersonType) => {
    if (!member) {
      return {initials: '?', fullName: ''}
    }
    const initials = member.initials
    const fullName = `${member.firstname} ${member.lastname}`
    return {initials, fullName}
  }

  return (
    <div>
      {membersArray.map((member, index) => {
        const {initials, fullName} = getMemberInfo(member)
        const key = `${Object.keys(team)[index]}-${index}`

        return (
          <Tooltip key={key} title={fullName}>
            <Avatar
              size='small'
              className={`avatar-group ${initials === '?' ? 'unknown-avatar-member' : ''}`}>
              {initials}
            </Avatar>
          </Tooltip>
        )
      })}
    </div>
  )
}
