import Link from 'next/link'
import {LookupItem} from '@shared/erp-api'
import {transformTasks} from '@libs/dashboard'
import {ProjectType, ProjectStatus, ColumnSize} from './dashboard.t'
import {Tooltip, Typography} from 'antd'
import {ColumnsType} from 'antd/lib/table'
import {AvatarGroup} from '@components/misc/avatar-group'
import {IndicatorDot} from '@components/misc/indicator-dot'
import {TFunction} from 'i18next'
import {CheckCircleTwoTone, ExclamationCircleTwoTone, ExportOutlined} from '@ant-design/icons'

const {Text} = Typography

const columnsSize = {
  defunct: '12vw', // don't forget to update .dashboard-column-element
  funerals: '7vw',
  team: '8vw',
  projectStatut: '5vw',
  completedSteps: '7.5vw',
  tasks: '9vw',
  houses: '5vw'
}

const createCommonColumns = (t: TFunction): ColumnsType<ProjectType> => [
  {
    title: (
      <div className='text-center'>
        <Text className='dashboard-header-title'>{t('dashboard.defunct')}</Text>
      </div>
    ),
    align: 'left',
    ellipsis: true,
    width: columnsSize.defunct,
    dataIndex: ['project', 'defunctName'],
    key: 'defunctName',
    render: (text: string, record: ProjectType) => {
      return (
        <div className='dashboard-column-element'>
          <IndicatorDot color={record.project.statusColor} text={record.project.status} />
          <Link
            href={{
              pathname: `/domain/projects/${record.project._id}`
            }}>
            <Text title={text} className='underline-hover'>
              {text || record.project.ref}
            </Text>
            <ExportOutlined className='ms-1' style={{fontSize: '10px'}} />
          </Link>
        </div>
      )
    }
  },
  {
    title: (
      <Tooltip title={t('dashboard.funeralsTooltip')}>
        <Text className='dashboard-header-title'>{t('dashboard.funerals')}</Text>
      </Tooltip>
    ),
    align: 'center',
    width: columnsSize.funerals,
    dataIndex: ['project', 'date'],
    key: 'date',
    render: (text: string) =>
      text ? (
        <Text>{text}</Text>
      ) : (
        <Tooltip title={t('dashboard.noStep')}>
          <ExclamationCircleTwoTone twoToneColor='red' style={{fontSize: '20px'}} />
        </Tooltip>
      )
  },
  {
    title: <Text className='dashboard-header-title'>{t('dashboard.houses')}</Text>,
    align: 'center',
    width: columnsSize.houses,
    key: 'houses',
    render: (text: string, record: ProjectType) => {
      const housesValue = record.houses?.map(({name}) => name).join(', ')

      return (
        <Text style={{width: columnsSize.houses}} ellipsis={{tooltip: housesValue}}>
          {housesValue}
        </Text>
      )
    }
  },
  {
    title: <Text className='dashboard-header-title'>{t('dashboard.team')}</Text>,
    align: 'center',
    width: columnsSize.team,
    key: 'teamMembers',
    render: (text: string, record: ProjectType) => {
      return <AvatarGroup team={record.teamMembers} />
    }
  },

  {
    title: (
      <Tooltip title={t('dashboard.completedStepsTooltip')}>
        <Text className='dashboard-header-title'>{t('dashboard.completedSteps')}</Text>
      </Tooltip>
    ),
    align: 'center',
    width: columnsSize.completedSteps,
    dataIndex: ['project', 'isComplete'],
    key: 'isComplete',
    render: (text: string, record: ProjectType) => {
      return record.project.isComplete ? (
        <CheckCircleTwoTone twoToneColor='#52c41a' style={{fontSize: '20px'}} />
      ) : (
        <Tooltip title={t('dashboard.noStep')}>
          <ExclamationCircleTwoTone twoToneColor='red' style={{fontSize: '20px'}} />
        </Tooltip>
      )
    }
  }
]

const passedColumns = (
  t: TFunction,
  columnsSize: ColumnSize,
  taskCategories: LookupItem[],
  taskCategoriesOrder?: LookupItem[]
) => [
  ...createCommonColumns(t),
  ...transformTasks(true, taskCategories, taskCategoriesOrder, columnsSize.tasks)
]

const futureColumns = (
  t: TFunction,
  columnsSize: ColumnSize,
  taskCategories: LookupItem[],
  taskCategoriesOrder?: LookupItem[]
) => [
  ...createCommonColumns(t),
  ...transformTasks(false, taskCategories, taskCategoriesOrder, columnsSize.tasks)
]

export const getColumnConfiguration = (
  columnType: ProjectStatus,
  t: TFunction,
  taskCategories: LookupItem[],
  taskCategoriesOrder?: LookupItem[]
) => {
  if (columnType === ProjectStatus.PASSED) {
    return passedColumns(t, columnsSize, taskCategories, taskCategoriesOrder)
  } else if (columnType === ProjectStatus.FUTURE) {
    return futureColumns(t, columnsSize, taskCategories, taskCategoriesOrder)
  } else {
    return []
  }
}
