import {useInmemoriServices} from '@services'
import {useMutation} from '@tanstack/react-query'
import {useRouter} from 'next/router'

const LogoutComponent = () => {
  const inmemoriServices = useInmemoriServices()
  const router = useRouter()

  const useLogoutMutation = useMutation({
    mutationFn: async () => inmemoriServices.authService.logout(),
    onSuccess: () => {
      router.push('/login').catch((e) => {
        // workaround for https://github.com/vercel/next.js/issues/37362
        if (!e.cancelled) {
          throw e
        }
      })
      window.location.reload()
    }
  })

  return <a onClick={() => useLogoutMutation.mutate()}>Déconnexion</a>
}

export default LogoutComponent
