import React from 'react'
import {Tooltip} from 'antd'

interface IndicatorDotProps {
  color: string
  text: string
}

export const IndicatorDot: React.FC<IndicatorDotProps> = ({color, text}) => {
  return (
    <>
      <Tooltip title={text}>
        <div
          className='dot'
          style={{
            backgroundColor: color,
            marginRight: '0.4rem',
            marginLeft: '0.3rem'
          }}></div>
      </Tooltip>
    </>
  )
}
