import {AppContext, withApp} from '@store/app'
import {Select, Skeleton, Typography} from 'antd'
import {House} from '@shared/interfaces'
import {useCallback, useMemo} from 'react'
import {Filters} from './dashboard.t'
import {UseQueryResult} from '@tanstack/react-query'

const {Title} = Typography

interface DashboardFiltersProps extends AppContext {
  disabled?: boolean
  houses: UseQueryResult<House[], unknown>
  filters?: Filters
  onFilterChange: (filters: Filters) => void
}

const DashboardFilters = ({
  disabled = false,
  houses,
  filters = {},
  onFilterChange,
  t,
  $profile
}: DashboardFiltersProps) => {
  const {data, isLoading, isError} = houses

  const onHouseFilterChange = useCallback(
    (value: string[]) => {
      onFilterChange({...filters, houses: value})
    },
    [filters, onFilterChange]
  )

  const houseOptions = useMemo(() => {
    let houses =
      data?.map((house) => ({
        label: house.name,
        value: house._id
      })) || []

    if ($profile?.houses.length) {
      houses = houses.filter((house) => $profile.houses.includes(house.value))
    }

    return houses
  }, [$profile, data])

  if (disabled) return null
  if (isError) return <div className='dashboard-centered'>{t('dashboard.error')}</div>

  if (houseOptions.length < 2) return null

  return (
    <>
      <Title level={5} className='dashboard-title'>
        {t('dashboard.filters.house.title')}
      </Title>
      {!!isLoading && <Skeleton.Input active={true} />}
      {!isLoading && (
        <Select
          mode='multiple'
          allowClear
          style={{width: '20rem'}}
          defaultValue={filters.houses}
          placeholder={t('dashboard.filters.house.placeholder')}
          onChange={onHouseFilterChange}
          options={houseOptions}
        />
      )}
    </>
  )
}

export default withApp(DashboardFilters)
